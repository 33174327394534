import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import ComingSoon from "../components/sections/coming-soon";
import CtaFooter from "../components/sections/cta-footer";
import Features from "../components/sections/features";
import Footer from "../components/sections/footer";
import Header from "../components/sections/header";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Get Ranger"
      description="Ranger is an Excel Add-in and plugin for Excel that provides a no-code SaaS solution for protecting, centralizing and storing Excel data by connecting it to the cloud."
    />
    <Navigation />
    <Header />
    <Features />
    <ComingSoon />
    <CtaFooter />
    <Footer />
  </Layout>
);

export default IndexPage;
