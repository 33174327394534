import React from "react";
import styled from "styled-components";

import { Section, Container } from "../global";

const ComingSoon = () => (
  <StyledSection>
    <Subtitle>Roadmap</Subtitle>
    <SectionTitle>Big Features Coming Soon</SectionTitle>
    <FeaturesGrid>
      <FeatureItem>
        <FeatureTitle>Integrations</FeatureTitle>
        <FeatureText>
          Integrate with other data sources like SQL and MongoDB and
          visualization tools like Tableau and PowerBI.
        </FeatureText>
      </FeatureItem>
      <FeatureItem>
        <FeatureTitle>Cross-Version Queries</FeatureTitle>
        <FeatureText>
          Build custom queries that allow you to join data across report
          versions and save the queries in your workbook.
        </FeatureText>
      </FeatureItem>
      <FeatureItem>
        <FeatureTitle>Data Source Tracking</FeatureTitle>
        <FeatureText>
          Save time reconciling data by easily accessing the origin of the data
          by author, workbook name, and file location.
        </FeatureText>
      </FeatureItem>
      <FeatureItem>
        <FeatureTitle>Data Flow Visualization</FeatureTitle>
        <FeatureText>
          Visualize how data flows around your organization and monitor when the
          reports are updated.
        </FeatureText>
      </FeatureItem>
    </FeaturesGrid>
  </StyledSection>
);

export default ComingSoon;

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.white};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`;

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`;

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`;

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`;

const FeatureText = styled.p`
  text-align: center;
`;
